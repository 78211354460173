// element bottom spacings
.ce_text,
.ce_hyperlink:not(.headline-link):not(.intro-link),
.ce_image,
.ce_list,
.ce_table,
.ce_downloads,
.ce_dzvideoembed
{
  margin-bottom: $element-bottom-spacer;
}

.ce_dzvideoembed {
  margin-bottom: 1.5 * $element-bottom-spacer;
}

// smaller margin between texts and links
.ce_text.standard, .ce_text.multi-column-2, .ce_text.multi-column-3 {
  & + .ce_text.hyperlinks,
  & + .ce_text.hyperlinks-inline {
    margin-top: -1 * 0.333 * $element-bottom-spacer;
  }
}

// remove bottom spacing for the last element in a column
.mod_article > .article_inside > .row:last-child,
.mod_article > .article_inside > .slick-list > .slick-track > .row,
{
  > .columns > div:last-child {
    margin-bottom:0;
  }
}

// remove bottom spacing for the last element in an article
.mod_article > .article_inside > :not(.row):last-child {
  margin-bottom:0;
}


// HEADLINES

.ce_headline h1 {
  @include h1_standard;
}


.ce_headline.standard h2, .ce_text.standard h2 {
  @include h2_standard;
}

.ce_headline.special h2, .ce_hyperlink.headline-link h2, .ce_hyperlink.intro-link h2 {
  @include text-headline;
  @include fontPx(38,38,40);  // todo: define the correct margin
  display: inline-block;
  text-transform: uppercase;
  padding-bottom: rem-calc(8);
  border-bottom: 7px solid $colorBorderBlue;
}

.ce_headline.headline-h1 + .ce_headline.headline-h2 {
  h2 {
    margin-top: -30px;
  }
}

.ce_hyperlink h2 {
  a {
    @include font-icon-after-inline($ifArrowRight, 28, 28);
    &:after {
      display: inline-block; position: relative; top: -1px;
    }

  }
}

section + .ce_headline {
  h2 {
    margin-top: rem-calc($element-bottom-spacer);
  }
}


.ce_headline h3, .ce_headline.standard h3 {
  @include h3_standard;
}
body:not(.homepage) .ce_text.standard h3 {
  @include h3_standard;
}
body.homepage .ce_text.standard h3, body:not(.homepage) section .ce_text.standard h3 {
  @include h3_teaser;
}
.ce_headline.special h3 {
  color: $colorTextDefault;
}


.ce_headline.standard h4, .ce_text.standard h4, .ce_list h4 {
  @include h4_standard;
}

.mod_article.bg_blue, .mod_article.bg_grey, .mod_article.custom_bgcolor {
  h2, h3, h4 {
    color: white !important;
    border-color: white !important;
  }
}

@include breakpoint(medium only) {
  .ce_hyperlink.intro-link h2 {
    paddding-bottom:0; border-bottom:0;
  }
}

@include breakpoint(small down) {
  .ce_headline h1 {
    @include h1_mobile;
  }
}


// TEXT

.ce_text {

  p {
    &:not(:last-child) {
      padding-bottom: $element-paragraph-spacer;
    }
  }

  &.standard {
    p {
      @include paragraph-standard;
    }

    .image_container.float_left {
      float:left;
      @include widthReducePercentByPixel(50%, $gridPadding);
      margin: 0 rem-calc($gridPadding) rem-calc(20) 0;
      margin-top: 5px;
      img {
        max-width: 100%;
        & + figcaption {
          margin-top: rem-calc(5);
        }
      }

      figcaption {
        @include figcaption;
      }
    }

    .image_container.float_above {
      margin-bottom: rem-calc(35);
    }

  }


  &.standard.intro {
    text-align: center;
    p {
      padding: 0 40px;  // todo
    }
  }

  &.standard.intro-left {
    p {
      @include text-bold;
    }
  }


  &.standard.interview-q {
    margin-bottom: rem-calc($element-bottom-spacer/2);
    p {
      @include text-bolditalic;
    }
    strong {
      color: $colorTextBlue;
    }
  }


  &.multi-column-2, &.multi-column-3 {
    p { @include paragraph-standard; }
  }
  &.multi-column-2 {
    column-count:2;
    column-gap: $multiColumnGap2;
  }

  &.multi-column-3 {
    column-count:3;
    column-gap: $multiColumnGap3;
  }


  &.standard.custom_bgcolor {
    padding: 40px;
    color: white;
    h3 {
      color: white !important;
    }
  }

  &.standard.level-bg {
    padding-top: 40px;
  }

  &.standard.text-float-left {
    float: left;
    @include widthReducePercentByPixel(50%, $gridSpacer);
    margin-bottom: rem-calc(30);
    margin-top: 4px;
    &.custom_bgcolor {
      margin-left: $gridPadding;
      margin-right: $gridSpacer;
    }
  }


  &.quote {
    .text__inside {
      background-image: url('images/bg_quote.png');
      background-position: 95px 0px;
      background-repeat: no-repeat;
    }
    .text_container {
      padding-left: rem-calc(250);
    }
    p:first-child {
      @include text-headline;
      @include fontPx(17,24,15);
      text-transform: uppercase;
      padding-bottom:0;
    }
    p:not(:first-child) {
      @include text-standard;
      @include fontPx(27,35,10);
      color: $colorTextBlue;
    }
  }

  &.contact {
    @include image-teaser-basic('contact');
  }

  &.teaser {
    @include image-teaser-basic;
  }

  &.infoblock {
    @include image-teaser-basic;
    > .text__inside {
      min-height: 176px;
      border-top: 1px solid $darkgrey;
      border-bottom: 1px solid $darkgrey;
      padding: 28px 0;
    }
    .image_container + .text_container {
      margin-left: 178px;
      padding-right: 30px;
    }
    h3 {
      @include text-regular;
      @include fontPx(21,26,15);
    }
    p {
      @include text-light;
      @include fontPx(19,23,10)

    }
  }

  p strong {
    @include text-strong;
  }
}

.mod_newsreader {
  .ce_text.contact {
    @include image-teaser-basic('contact-dark');
  }
}

@include breakpoint(medium down) {
  .ce_text {
    &.multi-column-2, &.multi-column-3 {
      column-count:1;
    }


  }
}

@include breakpoint(inter down) {
  .ce_text {
    &.standard {
      .image_container.float_left {
        float:none;
        width: 100%;
      }
    }
    &.infoblock {
      .image_container + .text_container {
        margin-left: 150px;
        padding-right: 0px;
      }
    }
    &.standard.level-bg {
      padding-top: 0px;
    }
    &.standard.intro {
      text-align: left;
      p {
        padding:0;
      }
    }
    &.standard.text-float-left {
      float:none;
      width: calc(100% - 20px);
      padding:40px !important;
      &.custom_bgcolor {
        margin-left: rem-calc(10);
        margin-right: rem-calc(10);
      }
    }
    &.quote {
      .text__inside {
        background-position: 0px 0px;
        background-repeat: no-repeat;
        background-size: 40px;
      }
      .text_container {
        padding-left: rem-calc(60);
      }
    }

  }
}

@include breakpoint(small down) {
  .ce_text {
    &.infoblock {
      .image_container {
        float: none; margin-bottom: 20px;
      }
      .image_container + .text_container {
        margin-left: 0px;
      }
    }
    &.contact, &.teaser {
      .image_container {
        float: none; margin-bottom: 20px;
        text-align: center;
      }
      .image_container + .text_container {
        margin-left: 0;
      }
    }
  }
}


// LISTS

.ce_text:not(.hyperlinks):not(.hyperlinks-inline):not(.ankerlinks):not(.interview-teaser), .ce_list {

  li {
    @include text-italic;
    @include fontPx(19,27,0);
    margin-bottom: rem-calc(10);
  }

  ul {
    li {
      @include font-icon-before($ifDot, 9, 23, 20, $colorTextBlue);
    }
  }
  &.custom_bgcolor {
    ul li:before {
      color: white;
    }
  }

  ol {
    @include list-numbers(19, 27, 20, $colorTextBlue);
  }

}


// Image

@include breakpoint(medium down) {
  .ce_image {

    &.horizontal-align-left {
      .image_container {
        text-align: center;
        > img {
          display: inline-block;
        }
      }
    }
  }

}


// HYPERLINKS

.ce_text {

  &.standard {
    p > a, li > a {
      @include link-standard;
    }

    p + ul {  // todo: find a better solution
      li {
        display: inline-block;
        &:not(:last-child) {
          margin-right: 40px;
        }
      }
    }

  }

  &.standard.custom_bgcolor {
    p > a, li > a {
      @include link-standard-white;
    }
  }

  &.hyperlinks, &.ankerlinks {
    p {
      @include paragraph-standard;
    }
    li {
      margin-bottom: rem-calc(20);
    }
    p > a, li > a {
      @include link-hyperlink;
    }

  }

  &.hyperlinks {
    p, li {
      padding-left: rem-calc($downlinkIndent);
    }
    p > a, li > a {
      @include link-hyperlink-types;
      /*
      @include font-icon-before('\f0c1', 17, 27);
      color: $colorTextBlue;
      &[href^="http"] {
        @include font-icon-before('\f08e', 17, 27);
      }
      &[href^="mailto"] {
        @include font-icon-before('\f1fa', 17, 27);
      }
      &[href$=".pdf"], &[href$=".jpg"] {
        @include font-icon-before('\f019', 17, 27);
      }
      */
    }
    p > a, li > a {
      margin-left: rem-calc(-1*$downlinkIndent);
      display: block !important;
      margin-bottom: rem-calc(7);
    }
  }

  &.hyperlink-topic {
    padding-left: 112px;
    background-repeat: no-repeat;
    background-position: left top;
    p {
      @include paragraph-small;
      padding-left: rem-calc(20);
      &:not(:last-child) {
        padding-bottom: rem-calc(10);
      }
    }
    p > a {
      @include link-navi;
      margin-left: rem-calc(-20);
    }
    &.link-agenda { background-image: url('images/bg_topiclink_agenda.png'); }
    &.link-caadp { background-image: url('images/bg_topiclink_caadp.png'); }
    &.link-climate { background-image: url('images/bg_topiclink_climate.png'); }
    &.link-gender { background-image: url('images/bg_topiclink_gender.png'); }
    &.link-governance { background-image: url('images/bg_topiclink_governance.png'); }
    &.link-nutrition { background-image: url('images/bg_topiclink_nutrition.png'); }
    &.link-privatesector { background-image: url('images/bg_topiclink_privatesector.png'); }
  }

  &.ankerlinks {
    p > a, li > a {
      @include font-icon-before($ifAnchor, 17, 27);
      color: $colorTextAnkerlink;
    }
  }

  &.hyperlinks-inline {

    li {
      display: inline-block;
      &:not(:first-child) {
        margin-left: rem-calc(30);
      }
      > a {
        @include link-standard;
        @include fontPx(19,19,0);
      }
    }

  }

}

.ce_hyperlink {

  &.standard {
    > a {
      @include text-link;
      @include fontPx(19,19,0);
      display: inline-block;
      text-transform: uppercase;
      padding-bottom: rem-calc(4);
      border-bottom: 3px solid $colorBorderBlue;
    }
  }

  &.intro-link {
    text-align: center;
  }

  &.contentnav-link {
    margin-bottom: rem-calc(15) !important;
    > a {
      @include font-icon-after-inline($ifArrowRight, 17, 24);
      @include text-link;
      @include fontPx(21,24,0);
      color: $colorTextBlue;
      display: inline-block;
      text-transform: uppercase;
    }
  }

}

.back {
  margin-top: rem-calc(30);
  display: inline-block;
  a {
    @include link-button;
    @include font-icon-before-inline($ifArrowLeft, 17, 24);
  }
  & + .top {
    padding:0 !important;
  }
}

.top {
  margin-top: rem-calc(30);
  display: inline-block;
  a {
    @include link-button;
    @include font-icon-before-inline($ifTop, 17, 24);
  }
}

@include breakpoint(inter down) {
  .ce_hyperlink {
    &.intro-link {
      text-align: left;
    }
  }
}


// TABLE

.ce_table {

  h3, td, th {
    @include colorbar;
    border-bottom: 4px solid white;
  }

  td:not(:last-child), th:not(:last-child) {
    border-right: 4px solid white;
  }

  table {
    width: 100%;
  }

  h3 {
    @include headerbar;
    width: 100%;
    background-color: $colorBgTableHeadline;
  }
  th {
    @include text-headline;
    color: white;
    text-align: left;
    background-color: $colorBgTableHeader;
  }
  td {
    @include text-regular;
  }
  tr.even {
    td {
      background-color: $colorBgTableContentEven;
    }
  }
  tr.odd {
    td {
      background-color: $colorBgTableContentOdd;
    }
  }

}

@include breakpoint(medium down) {
  .ce_table {
    .table-inside {
      overflow-x: auto;
    }

  }
}

// DOWNLOADS

%download_link {
  @include font-icon-before($ifDownload, 16, 26);
  @include text-link;
  @include fontPx(21,26,0);
  color: $colorTextBlue;
  text-transform: uppercase;
}

.ce_downloads {
  .mime_icon { display: none; }

  h3 {
    @include text-regular;
    @include fontPx(17,22,30);
    text-transform: uppercase;
    strong {
      @include text-headline;
    }
  }

  .downloads__intro {
    @include text-standard;
    @include fontPx(18,24,0);
    //margin-left: rem-calc($downlinkIndent);
    margin-bottom: rem-calc(15);
  }

  li {
    margin-bottom: rem-calc(20);
  }
  a {
    @extend %download_link;
    margin-bottom: rem-calc(7);
  }
  .download-description {
    display:block;
    @include text-standard;
    @include fontPx(18,24,0);
    margin-left: rem-calc($downlinkIndent);
    margin-bottom: rem-calc(10);
  }
}


// VIDEO


.ce_dzvideoembed {

  h3 {
    @include text-headline;
    @include fontPx(21,28,20);
  }
  .dzvideoembed {
    &__caption {
      background-color: $midgrey;
      padding: rem-calc(30);
    }

    &__headline {
      @include text-headline;
      @include fontPx(21,28,20);
    }

    &__description {
      p {
        @include paragraph-small;
      }
      p > a, li > a {
        @include link-hyperlink('small');
        @include link-hyperlink-types('small');
      }
    }

    &__wrapper_16x9 {
      @include videowrapper();
    }
  }
}


// SCRIBD

.scribd_wrapper {
  margin-bottom: $element-bottom-spacer;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  > iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    border: 0;
  }
}


// COLOR THEMES

.mod_article {
  &.bg_blue,
  &.bg_grey {

    // Headlines
    h2.ce_headline, .ce_hyperlink h2 {
      color: white; border-color: white;
      a { color: white; }
    }

    h3.ce_headline, h3.ce_headline.standard, .ce_text.standard h3 {
      color: white;
    }

    // Text
    .ce_text {
      p {
        color: white;
      }
      &.standard {
        p > a { color: white; border-color: white; }
      }
    }

    // Hyperlink
    .ce_hyperlink {
      &.standard {
        > a { color: white; border-color: white; }
      }
    }

  }
}

.ce_text.custom_bgcolor {
  h3 {
    color: white;
  }
}






