@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?mnp7sl');
  src:  url('../fonts/icomoon.eot?mnp7sl#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?mnp7sl') format('truetype'),
    url('../fonts/icomoon.woff?mnp7sl') format('woff'),
    url('../fonts/icomoon.svg?mnp7sl#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-contact:before {
  content: "\e901";
}
.icon-magnifier:before {
  content: "\e902";
}
.icon-bullet:before {
  content: "\e903";
}
.icon-arrow_top:before {
  content: "\e904";
}
.icon-arrow_bottom:before {
  content: "\e909";
}
.icon-Backward:before {
  content: "\e90a";
}
.icon-arrow_left:before {
  content: "\e90b";
}
.icon-arrow_right:before {
  content: "\e90c";
}
.icon-Forward:before {
  content: "\e90d";
}
.icon-anchor:before {
  content: "\e900";
}
.icon-Download:before {
  content: "\e905";
}
.icon-Link_extern:before {
  content: "\e906";
}
.icon-Link_intern:before {
  content: "\e907";
}
.icon-twitter:before {
  content: "\e908";
}


