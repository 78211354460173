.float-columns {
  .article_inside { display: none; }

}

.fc-cols {
  @include page-width;
  @include clearfix_after;
}


.fc-col {
  float:left;
  padding:0 $gridPadding/2;
  &:first-child {
    padding-left: $gridPadding;
  }
  &:last-child {
    padding-right: $gridPadding;
  }
}



.fc-col-5 { width:20%; }
.fc-col-4 { width:25%; }
.fc-col-3 { width:33.3333%; }
.fc-col-2 { width:50%; }
.fc-col-1 { width:100%; }

.fc-elem {
  @include text-footer;


  background-color: white;
  border: 1px solid #e3e3e3;
  padding: 30px;
  margin-bottom: 25px;


  figure { margin:0; }
  img { margin:0; vertical-align: baseline; }
  .text_container { padding:20px; }
}

.ce-dz-socialmedia__meta_top {
  @include fontPx(17,24,0);
  vertical-align: baseline;
  margin-bottom: 8px;
}

.ce-dz-socialmedia-name {
  display: inline-block;
  @include text-regular;
  margin-left: 15px;
}

.ce-dz-socialmedia-date {
  display: inline-block;
  margin-right: 5px;
}

.ce-dz-socialmedia-screenname {
  display: inline-block;
  margin-left: 5px;
}

.ce-dz-socialmedia__text {
  @include text-regular;
  @include fontPx(15,20,0);
  margin-bottom: 10px;
}

.ce-dz-socialmedia__meta_bottom {
  @include fontPx(14,20,0);
}


@include breakpoint(inter down) {
  .fc-cols {
    padding:0 rem-calc(10);
  }
  .fc-col {
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
}