.interview-teaser {

  padding-top: 40px;

  h3 {
    @include text-headline;
    @include fontPx(17,24,15);
    text-transform: uppercase;
    em {
      color: $colorTextBlue;
      font-style: normal;
    }
  }

  p:not(:last-child) {
    @include text-standard;
    @include fontPx(27,36,0);
    &:not(:last-child) {
      padding-bottom: $element-paragraph-spacer;
    }
  }

  li {
    display: inline-block;
    &:not(:first-child) {
      margin-left: rem-calc(30);
    }
    > a {
      @include link-standard;
    }
  }

}


.interview_listteaser {
  margin-bottom: rem-calc(65);
  @include news-teaser-basic;
  h3 {
    @include h3_teaser;
    margin-top: rem-calc(30);
  }
}


@include breakpoint(medium only) {
  .interview_listteaser {
    h3 {
      margin-top: 0;
    }
  }
}

@include breakpoint(inter down) {
  .interview_listteaser {
    .image_container {
      text-align: center;
    }
  }
}