.mod_search {

  button, input[type="submit"] {
    @include form-button;
  }

  .formbody {
    width: 66%;
    display: flex;
    margin-bottom: rem-calc(25);

    input[type="search"] {
      flex: 1;
    }

    input[type="submit"] {
      display: inline-block;
      position: relative;
      width: auto;
    }

  }

  .header {
    @include text-light;
    @include fontPx(18,18,0);
    margin-bottom: rem-calc(30);
  }

  .header + .pagination {
    @include pagination-top;
  }

  div.even, div.odd {
    margin-bottom: $element-bottom-spacer;
  }

  h3 {
    @include text-headline;
    @include fontPx(28,36,10);
    color: $colorTextBlue;
    a {
      color: inherit;
    }
  }

  .context {
    @include text-standard;
    @include fontPx(19,27,0);
    margin-bottom: rem-calc(10);
  }

  .url {
    a {
      @include link-simple;
    }
  }

}