.publication_latest {
  margin-bottom: $element-bottom-spacer;

  .news-text-container {
    padding-top: 40px;
  }

  h3 {
    @include h3_standard;
  }

  .teaser {
    margin-bottom: $element-paragraph-spacer;
    p {
      @include paragraph-standard;
    }
  }

  p.more > a {
    @include link-standard;
  }


}