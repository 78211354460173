.topic-tabs {

  // resets - start
  .tabs {
    border:0;
  }
  .tabs-title {
    > a {
      padding:0;
    }
  }
  .tabs-title > a:focus, .tabs-title > a[aria-selected='true'] {
    background-color:transparent;
  }
  .tabs-content {
    border:0;
  }
  .tabs-panel {
    padding:0;
  }
  // resets - end


  .tabs {
    position:relative; z-index:2;
    background-color: transparent;
  }

  .tabs-title {
    width: calc(100%/7);
    background-color: transparent;

    > a {
      @include text-headline;
      @include fontPx(17,20,0);
      text-transform: uppercase;
      text-align:center;
      background-color:transparent;
      background-repeat: no-repeat, no-repeat;
      background-position: center top, center bottom;
      background-size: auto, auto;
      padding-top: 130px;
      //padding-bottom: 80px;
      min-height: 250px;
    }

    @for $i from 1 through 7 {
      &:nth-child(#{$i}) {
        > a {
          background-image: url('images/bg_tab_#{$i}.png'), url('images/trans.png');
          &[aria-selected='true'] {
            background-image: url('images/bg_tab_#{$i}_high.png'), url('images/bg_tab_active.png');
          }
          &:hover:not([aria-selected='true']) {
            background-image: url('images/bg_tab_#{$i}_high.png'), url('images/trans.png');
          }
        }
      }
    }

  }

  .tabs-content {
    position: relative; z-index: 1; top: -1px;
    border-top: 1px solid black;
    padding-top: 30px;
  }


}

@include breakpoint(large only) {
  .topic-tabs {
    .tabs-title {
      > a {
        background-size: 100px, auto;
        //margin-left: 10px; margin-right: 10px;
      }
    }
  }
}

@include breakpoint(inter down) {
  .topic-tabs {
    .tabs-title {
      > a {
        background-size: 80px, auto;
        padding-top: 90px;
        min-height: 190px;
        //margin-left: 10px; margin-right: 10px;
      }
    }
  }
}

@include breakpoint(medium down) {

  .topic-tabs {
    .tabs {
      background-repeat: no-repeat;
      background-position: center bottom;
      background-image: url('images/bg_tab_active.png');
    }
    .tabs-title {
      //padding-left:10px; padding-right:10px;
      > a {
        background-repeat: no-repeat;
        background-position: center top;
      }

      @for $i from 1 through 7 {
        &:nth-child(#{$i}) {
          > a {
            background-image: url('images/bg_tab_#{$i}.png');
            &[aria-selected='true'] {
              background-image: url('images/bg_tab_#{$i}_high.png');
            }
          }
        }
      }
    }
  }

}

@include breakpoint(medium only) {

  .topic-tabs {
    .tabs-title {

      @for $i from 1 through 4 {
        &:nth-child(#{$i}) {
          width: calc(100%/4);
          > a {
            min-height: 190px;
          }
        }
      }
      @for $i from 5 through 7 {
        &:nth-child(#{$i}) {
          width: calc(100%/4);
        }
      }
      &:nth-child(5) {
        margin-left: calc(100%/8);
      }


    }
  }

}

@include breakpoint(inter only) {

  .topic-tabs {
    .tabs-title {

      @for $i from 1 through 4 {
        &:nth-child(#{$i}) {
          width: calc(100%/4);
          > a {
            min-height: 160px;
          }
        }
      }
      @for $i from 5 through 7 {
        &:nth-child(#{$i}) {
          width: calc(100%/4);
        }
      }
      &:nth-child(5) {
        margin-left: calc(100%/8);
      }


    }
  }

}

@include breakpoint(small down) {

  .topic-tabs {
    .tabs-title {

      @for $i from 1 through 6 {
        &:nth-child(#{$i}) {
          width: calc(100%/3);
          > a {
            min-height: 150px;
          }
        }
      }
      @for $i from 7 through 7 {
        &:nth-child(#{$i}) {
          width: calc(100%/2);
        }
      }
      &:nth-child(7) {
        margin-left: calc(100%/4);
        > a {
          min-height: 160px;
        }
      }

    }
  }

}