@mixin clearfix {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

@mixin clearfix_after {
  &:after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }
}


@mixin inside {
  max-width: rem-calc(1200); margin: 0 auto;

}

@mixin resetGridPadding {
  margin-left: -1*$gridPadding;
  margin-right: -1*$gridPadding;
}


@mixin page-padding ($padding: $page-padding) {

  @if type-of($padding) == 'map' {

    @each $breakpoint, $value in $padding {
      $padding: rem-calc($value);

      @include breakpoint($breakpoint) {
        padding-left: $padding;
        padding-right: $padding;
      }
    }
  }
  @else if type-of($padding) == 'number' and strip-unit($padding) > 0 {
    $padding: rem-calc($gutter);
    padding-left: $padding;
    padding-right: $padding;
  }

}


@mixin page-width ($width: $page-width) {
  margin: 0 auto;

  @if type-of($width) == 'map' {

    @each $breakpoint, $value in $width {
      @include breakpoint($breakpoint) {
        max-width: rem-calc($value);
      }
    }
  }
  @else if type-of($width) == 'number' and strip-unit($width) > 0 {
    max-width: rem-calc($width);
  }

}


@mixin single-column($gutter: $grid-column-gutter) {

  @if type-of($gutter) == 'map' {
    @each $breakpoint, $value in $gutter {
      $padding: rem-calc($value) / 2;

      @include breakpoint($breakpoint) {
        padding-left: $padding;
        padding-right: $padding;
      }
    }
  }
  @else if type-of($gutter) == 'number' and strip-unit($gutter) > 0 {
    $padding: rem-calc($gutter) / 2;
    padding-left: $padding;
    padding-right: $padding;
  }

}


@mixin columnSpacerBottom($type: '50x50', $spacer: $grid-spacer-vertical) {

  @if $type == '33x33x33' {
    $spacer: $grid-spacer-vertical-33x33x33;
  }


  @if type-of($spacer) == 'map' {

    @each $breakpoint, $value in $spacer {
      @include breakpoint($breakpoint) {
        padding-bottom: rem-calc($value);
      }
    }
  }
  @else if type-of($spacer) == 'number' and strip-unit($spacer) > 0 {
    padding-bottom: rem-calc($spacer);
  }
}


// Headlines

@mixin h1_standard {
  @include text-headline;
  @include fontPx(48,58,40);  // todo: define the correct margin
  color: $colorHeadlineH1;
  text-transform: uppercase;
}
@mixin h1_mobile {
  @include fontPx(38,48,30);  // todo: define the correct margin
}


@mixin h2_standard {
  @include text-headline;
  @include fontPx(36,44,20);  // todo: define the correct margin
}

@mixin h3_standard {
  @include text-headline;
  @include fontPx(28,36,15);  // todo: use correct values
  color: $colorHeadlineH1;
  //text-transform: uppercase;
}

@mixin h3_teaser {
  @include text-headline;
  @include fontPx(21, 28, 15);
  color: $colorTextDefault;
  text-transform: uppercase;
}

@mixin h4_standard {
  @include text-headline;
  @include fontPx(21,27,15);  // todo: use correct values
  text-transform: uppercase;
}


// TEXT

@mixin paragraph-standard {
  @include text-standard;
  @include fontPx(19,27,0);
  &:not(:last-child) {
    padding-bottom: $element-paragraph-spacer;
  }
}

@mixin paragraph-small {
  @include text-standard;
  @include fontPx(18,26,0);
  &:not(:last-child) {
    padding-bottom: $element-paragraph-spacer-small;
  }
}

@mixin paragraph-standard-white {
  @include paragraph-standard;
  color: white;
}

@mixin paragraph-small-white {
  @include paragraph-small;
  color: white;
}

@mixin text-strong {
  font-weight: normal;
  @include text-bold;
}

@mixin figcaption {
  @include text-regular;
  @include fontPx(18,23,0);
  color: $colorTextBlue;
}


// Teaser

@mixin news-teaser-basic {
  h3 {
    @include h3_standard;
  }

  .teaser {
    margin-bottom: $element-paragraph-spacer;
    p {
      @include paragraph-standard;
      strong {
        @include text-bold;
      }
    }
  }

  p.more, p.moreoverview {
    display: inline-block;
    > a {
      @include link-standard;
    }
    + p.moreoverview {
      margin-left: rem-calc(30);
    }
  }

}


@mixin image-teaser-basic($colorTheme: 'standard') {

  .image_container {
    float: left;
    & + .text_container {
      margin-left: rem-calc(150);
    }
  }
  h3 {
    @if (($colorTheme == 'contact') OR ($colorTheme == 'contact-dark')) {
      @include text-regular;
      @include fontPx(17,24,0);
    } @else {
      @include h3_teaser;
    }
    @if ($colorTheme == 'light') {
      color: white;
    }
    text-transform: uppercase;
    strong {
      @include text-headline;
      //font-style: italic;
    }
  }
  h4 {
    @include h3_teaser;
    @if (($colorTheme == 'light') OR ($colorTheme == 'contact')) {
      color: white;
    }
  }
  p {
    @if (($colorTheme == 'light') OR ($colorTheme == 'contact')) {
      @include paragraph-small-white;
    } @else {
      @include paragraph-small;
    }

  }
  p > a {
    @if (($colorTheme == 'light') OR ($colorTheme == 'contact')) {
      @include link-standard-white;
    } @else {
      @include link-standard;
    }
  }
  @if (($colorTheme == 'contact-dark')) {
    h4, p, p > a {
      color: $colorTextDefault;
    }

  }

}


// Video

@mixin videowrapper($ratioX: 16, $ratioY: 9) {
  height: 0;
  padding-bottom: ($ratioY*100%/$ratioX);
  //padding-top: 25px;
  position: relative;
  > iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    border: 0;
  }
}

// Header-Bars

@mixin colorbar {
  @include fontPx(21,27,0);
  padding: rem-calc(17) rem-calc(40);
  position: relative;
}
@mixin headerbar {
  @include colorbar;
  @include text-headline;
  @include fontPx(21,28,0);
  color: white;
  text-transform: uppercase;
}
@mixin headerbar-right {
  @include icon-bar-after($ifArrowNext, 28);
}
@mixin headerbar-down {
  @include icon-bar-after($ifArrowDown, 28);
}

// HYPERLINKS

@mixin link-simple {
  @include text-link;
  @include fontPx(17,17,0);
  display: inline-block;
  text-transform: uppercase;
}

@mixin link-standard {
  @include link-simple;
  padding-bottom: rem-calc(4);
  border-bottom: 3px solid $colorBorderBlue;
}

@mixin link-standard-white {
  @include link-standard;
  color: white;
  border-color: white;
}

@mixin link-navi {
  @include text-link;
  @include fontPx(21,26,0);
  @include font-icon-before($ifArrowRight, 15, 26, 20);
  display: inline-block;
  text-transform: uppercase;
  color: $colorTextBlue;
  &:before { color: inherit; }
}

@mixin widthReducePercentByPixel($widthPercent, $widthPixel) {
  width: calc(#{$widthPercent} - #{$widthPixel});
}

@mixin link-hyperlink($type: 'default') {
  $fs: 21;
  $lh: 27;
  @if $type == 'small' {
    $fs: 18;
    $lh: 24;
  }
  @include text-link;
  @include fontPx($fs,$lh,0);
  display: inline-block;
  text-transform: uppercase;
}

@mixin link-hyperlink-types($type: 'default') {
  $fs: 17;
  $lh: 27;
  $ind: $downlinkIndent;
  @if $type == 'small' {
    $fs: 15;
    $lh: 24;
    $ind: 27;
  }
  @include font-icon-before($ifLinkStandard, $fs, $lh, $ind);
  color: $colorTextBlue;
  &[href^="http"] {
    @include font-icon-before($ifLinkExtern, $fs, $lh, $ind);
  }
  &[href^="mailto"] {
    @include font-icon-before($ifMail, $fs, $lh, $ind);
  }
  &[href$=".pdf"], &[href$=".jpg"] {
    @include font-icon-before($ifDownload, $fs, $lh, $ind);
  }
}

@mixin link-button {
  @include text-headline;
  @include fontPx(21,21,0);
  color: white;
  background-color: $colorBgFormButton;
  padding: rem-calc(7) rem-calc(20);
}

// List Numbers

@mixin list-numbers($fontSize, $lineHeight, $indent: $downlinkIndent, $color: $colorTextDefault) {
  counter-reset: my-ol-counter;
  li {
    position: relative;
    display: block;
    padding-left: rem-calc($indent);
    &:before {
      position: absolute; top:0; left:0; z-index:1;
      content: counter(my-ol-counter);
      counter-increment: my-ol-counter;
      @include fontPx($fontSize,$lineHeight,0);
      font-weight: bold;
      font-style: normal;
      color: $color;
      //margin-left: 2px;
    }
  }
}

// Font Icons

@mixin font-icon-before($content, $fontSize, $lineHeight, $indent: $downlinkIndent, $color: $colorTextDefault) {
  position: relative;
  display: block;
  padding-left: rem-calc($indent);
  &:before {
    position: absolute; top:0; left:0; z-index:1;
    @include icon-font;
    content: $content;
    @include fontPx($fontSize,$lineHeight,0);
    font-weight: normal;
    font-style: normal;
    color: $color;
    margin-left: 2px;
  }
}

@mixin font-icon-before-inline($content, $fontSize, $lineHeight) {
  &:before {
    @include icon-font;
    font-weight:normal;
    content: $content;
    @include fontPx($fontSize, $lineHeight, 0);
    padding-right: rem-calc(10);
  }
}

@mixin font-icon-after-inline($content, $fontSize, $lineHeight) {
  &:after {
    @include icon-font;
    font-weight:normal;
    content: $content;
    @include fontPx($fontSize, $lineHeight, 0);
    padding-left: rem-calc(7);
  }
}

@mixin icon-bar-after($content, $fontSize, $color: inherit) {
  padding-right: rem-calc(60);
  &:after {
    position: absolute; top:50%; right: rem-calc(26);
    transform: translateY(-50%);
    content: $content;
    color: $color;
    @include icon-font;
    @include fontPx($fontSize,1,0);
    line-height: 1;
    font-weight: normal;
    font-style: normal;
  }
}
