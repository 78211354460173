.main {
  //padding-top:50px;
}

.wireframe {
  border:1px solid black;
  padding:15px;
}

body.show_grid {
  background-color: grey;

  .main > .mod_article {
    //background-color: green;
  }

  .main > .mod_article > .article_inside {
    background-color: blue;
  }

  .ce_text, .ce_hyperlink, .ce_headline {
    background-color: white;
  }
}


.bg_red {
  background-color: red;
}
.bg_green {
  background-color: green;
}

.bg_white {
  background-color: #ffffff;
}

.bg_blue {
  background-color: #5a91cd;
}

.bg_grey {
  background-color: #6e6e6e;
}


/*
.bg_blue, .bg_grey, .bg_image {
  color: white;
  a {
    color:white;
  }
  .wireframe {
    border-color:white;
  }
}

.bg_image {
  background:url('images/IMG_5DM2_038255.jpg') no-repeat center center;
  background-position: cover;
}
*/

body.show-grid .main > .mod_article > .article_inside {
  //background-color: rgba(0,0,0,0.05);
}


/*
.main > .mod_article {

  padding-top: 30px;
  padding-bottom: 30px;
  &.no-padding {
    padding-top:0; padding-bottom:0;
  }
  &.no-padding-top {
    padding-top:0;
  }
  &.no-padding-bottom {
    padding-bottom:0;
  }

}
*/

.mod_article {
  position:relative;
}


.dev_nav {
  @include inside;
  padding-top: 80px;
  padding-left: $gridPadding;
  padding-right: $gridPadding;
  li {
    font-size:0;
    display:inline-block; float:left;
    margin-right:2px;
  }
  a, span {
    @include fontPx(16,16,0);
    background-color: #dddddd;
    padding: 3px 5px;
  }
  .active {
    background-color: #888888;
    color: white;

  }
}

.article_dev_name {
  display:none;
  position:absolute; top:5px; right:5px; z-index:1000;
  background-color: white;
  color:black;
  border: 1px solid black;
  padding:5px 20px;
}

// -----------------

//.development {

  .dev_headerinfo {
    display:none;
    position: fixed; top: 0; left: 0; z-index: 1000;
    width: 100%; height: 30px;
    text-align: center;
    margin: 0 auto;
    #screenwidth {
      display: inline-block;
      background-color: black;
      color: white;
      padding: 10px;
      //margin:0 auto;
      &:after {
        content: '  /  breakpoint: xxlarge';
      }
    }
    .toggle-label {
      background-color: #dddddd;
      padding: 12px 20px;
    }
  }

  body.dev-show-label {
    .article_dev_name {
      display:block;
    }
  }


  .dev_article_documentation {
    margin-bottom: 30px;
    h3, h4 {
      a {
        &:after {
          @include icon-font;
          content: $ifArrowNext;
          padding-left: 10px;
        }
      }
    }
    .dev_article_description {}

  }

  @include breakpoint(xlarge only) {
    .dev_headerinfo #screenwidth {
      background-color: darkred;
      &:after {
        content: '  /  breakpoint: xlarge';
      }
    }
  }
  @include breakpoint(large only) {
    .dev_headerinfo #screenwidth {
      background-color: red;
      &:after {
        content: '  /  breakpoint: large';
      }
    }
  }
  @include breakpoint(medium only) {
    .dev_headerinfo #screenwidth {
      background-color: orange;
      &:after {
        content: '  /  breakpoint: medium';
      }
    }
  }
  @include breakpoint(small only) {
    .dev_headerinfo #screenwidth {
      background-color: #ffffe0; color: black;
      &:after {
        content: '  /  breakpoint: small';
      }
    }
  }
  @include breakpoint(inter only) {
    .dev_headerinfo #screenwidth {
      background-color: yellow; color: black;
      &:after {
        content: '  /  breakpoint: inter';
      }
    }
  }

//}