.pagination {

  text-align:center;
  p { display:none; }
  li { display:inline-block; padding:0 5px; }
  li.next { padding-left:20px; }
  li.previous { padding-right:20px; }
  a, span { @include text-headline; @include fontPx(28,28,0); }
  span { color: $colorBgBlue; }
  a.next, a.last, a.previous, a.first {
    font-size:0; display:inline-block;
    &:after { @include icon-font; font-weight:normal; font-size:22px; }
  }
  a.next:after { content: $ifArrowNext; }
  a.last:after { content: $ifArrowLast; }
  a.previous:after { content: $ifArrowPrev; }
  a.first:after { content: $ifArrowFirst; }

}

.pagination:last-of-type {
  padding-top: rem-calc(20);
  border-top: 1px solid black;
  margin-top: rem-calc(25);
}

@mixin pagination-top {
  padding-bottom: rem-calc(20);
  border-bottom: 1px solid black;
  margin-bottom: rem-calc(25);

}