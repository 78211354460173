.wrapper > .header {
  position: relative;
  min-height: 295px;

  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-image: url('images/bg_header_content.jpg');

  & > .mod_article {
    position: relative; z-index: 5;
    min-height: inherit;
    text-align:center;

    @include page-padding;

    &.bg_hasimage {
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      background-image: url('images/bg_header_content.jpg');
    }

    &.article_padding_top {
      padding-top: $paddingArticleTop;
    }
    &.article_padding_bottom {
      padding-bottom: $paddingArticleBottom;
    }

  }

}

.homepage .wrapper > .header {
  min-height: 643px;
}


.logo {
  position: absolute;
  left: 45px; top: 0; z-index: 10;
  a {
    display:inline-block;
  }
}

.header-mobile {
  //display: none;
  background-color: white;
  @include page-padding;
  padding-top: 10px; padding-bottom:10px;
  .logo-mobile {
    img {

    }
  }
}





.ce_text.standard.header-home {

  display: inline-block;
  height: 563px; width: 563px;
  position: relative;
  background-color: $colorBgBlue;
  color: white;
  border-radius: 563px;
  text-align: center;

  & > .text__inside {
    position: relative; top: 50%; transform: translateY(-50%);
    padding: 0 80px;
  }

  h1 {
    @include text-headline;
    @include fontPx(35,44,25);
  }

  p {
    @include text-header;
    @include fontPx(24,29,0);
    > a {
      color: white;
      @include text-link;
      //@include font-icon-after-inline($ifArrowRight, 19, 19);
      @include fontPx(19,19,0);
      display: inline-block;
      text-transform: uppercase;
      padding-bottom: rem-calc(4);
      border-bottom: 3px solid white;
    }
  }

}


@include breakpoint(medium up) {
  .header-mobile {
    height:1px !important; overflow:hidden; background-color: grey; padding:0;
    > div {
      display:none;
    }
  }
}

@include breakpoint(inter down) {
  .header-mobile {
    display: block;
  }
  .logo {
    display: none;
  }
  .homepage .wrapper > .header {
    min-height:0;
    max-height: 105vw;
  }
  .ce_text.standard.header-home {
    width: 80vw; height: 80vw;
    border-radius: 80vw;
    & > .text__inside {
      padding: 0 5vw;
    }
    h1 {
      font-size: 5vw;
    }
    p {
      font-size:3vw;
      > a {
        font-size: 3vw;
      }
    }
  }
}

@include breakpoint(small down) {
  .ce_text.standard.header-home {
    p {
      font-size:4vw;
    }
  }
}