// disable chromes blue focus outline
textarea, input, *:focus { outline: none !important; }

@mixin form-text {
  @include text-standard;
  @include fontPx(21,21,0);
  width: 100%;
  height: 53px;
  padding-left: rem-calc(10);
  padding-right: rem-calc(10);
  background-color: $colorBgFormField;
  border: none;
  margin:0;
  -webkit-appearance: none;
}

@mixin form-select {
  @include text-regular;
  @include fontPx(21,21,0);
  width: 100%;
  height: 53px;
  padding-left: rem-calc(10);
  padding-right: rem-calc(10);
  background-color: $colorBgFormField;
  border: none;
  margin:0;
}

@mixin form-select-custom {
  background-color: transparent;
  border: none;
  box-shadow: none;
  background-image: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

}


@mixin form-button {
  height: 53px;
  padding-left: rem-calc(30);
  padding-right: rem-calc(30);
  @include text-headline;
  @include fontPx(21,21,0);
  text-transform: uppercase;
  color: $colorTextFormButton;
  background-color: $colorBgFormButton;
  border:none;
  margin:0;
  display: inline-block;
  box-sizing: border-box;
}

input[type="text"], input[type="search"], input[type="email"] {
  @include form-text;
}

textarea {
  @include form-text;
  height: rem-calc(100);
}



.captcha_text {
  padding-top: rem-calc(4);
  @include text-light;
  @include fontPx(16,20,0);
}

.ce_form {

  button, input[type="submit"] {
    @include form-button;
  }

  .formbody {

    > section.row, > .widget {
      margin-bottom: rem-calc(25);
    }
  }

  label {
    @include text-standard;
    @include fontPx(18,24,0);
  }

  .columns > .submit_container {
    text-align: right;
    padding-top: rem-calc(24);
  }
}