// simulate grid padding for elements not included in a colset
.main > .mod_article > .article_inside {
  > :not(.row):not(.slick-list):not(.slick-arrow):not(.mod_newslist):not(.mod_newsarchive) {
    @include single-column();
  }
}

.main > .mod_article > .article_inside .slick-track {
  > :not(.row) {
    @include single-column();
  }
}




// vertical spacing for columns on smaller screens
.col-50x50 {
  &:not(.first-col-empty) > :not(:last-child) {
    @include columnSpacerBottom;
  }
}

.col-33x33x33 {
  &:not(.first-col-empty) > :not(:last-child) {
    @include columnSpacerBottom('33x33x33');
  }
}

.col-33x33x33:last-child {
  .columns {
    > div:last-child {
      margin-bottom: 0;
    }
  }
}

.row.border-top {
  margin-top: 3*$element-paragraph-spacer;
  border-top: 1px solid $grey;
  padding-top: rem-calc(40);
}
.row.border-bottom {
  border-bottom: 1px solid $grey;
  //padding-bottom: rem-calc(20);
  margin-bottom: $element-paragraph-spacer;
}

/*
.row .row.inner {

  margin-left:0; margin-right:0;

  .columns:first-child {
    padding-left:0;
  }
  .columns:last-child {
    padding-right:0;
  }
}
*/

.row.inner {
  .columns:first-child {
    padding-left:0;
  }
  .columns:last-child {
    padding-right:0;
  }
}


@include breakpoint(inter down) {
  .col-50x50.inner {
    .columns {
      padding-left: 0; padding-right: 0;
      > div:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@include breakpoint(medium down) {
  .col-33x33x33 {
    .columns {
      > div:last-child {
        margin-bottom: 10px;
      }
    }
  }

}