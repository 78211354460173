%news_teaser {
  margin-bottom: $element-bottom-spacer;

  .news-text-container {
    padding-top: 40px;
  }

  h3 {
    @include h3_teaser;
  }

  .teaser {
    margin-bottom: $element-paragraph-spacer-small;
    p {
      @include paragraph-small;
    }
    p strong {
      @include text-strong;
    }
  }

  p.more > a {
    @include link-standard;
  }

}


.news_teaser {
  @extend %news_teaser;



}


.news_teaser_icon {
  @extend %news_teaser;


  .icon {
    width: 73px;
    height: 73px;
    background-position: top left;
    background-repeat: no-repeat;
    background-image: url('images/bg_icon_general.png');
    margin-bottom: rem-calc(20);
  }

  &.general {
    .archivename {
      display:none;
    }
  }
  &.agenda {
    .icon { background-image: url('images/bg_icon_agenda.png'); }
  }
  &.caadp {
    .icon { background-image: url('images/bg_icon_caadp.png'); }
  }
  &.climate-change {
    .icon { background-image: url('images/bg_icon_climate.png'); }
  }
  &.gender {
    .icon { background-image: url('images/bg_icon_gender.png'); }
  }
  &.land-governance {
    .icon { background-image: url('images/bg_icon_land.png'); }
  }
  &.nutrition {
    .icon { background-image: url('images/bg_icon_nutrition.png'); }
  }
  &.private-sector-and-trade {
    .icon { background-image: url('images/bg_icon_privatesector.png'); }
  }


}

.no-icons .news_teaser_icon {
  .icon {
    display:none;
  }
}


.mod_newsreader {

  h1 {
    @include h1_standard;
    + .ce_text > h2 {
      margin-top: rem-calc(-20);
    }
  }



}

.mod_newsmenu {
  margin-bottom: $element-bottom-spacer;
  li {
    margin-bottom: rem-calc(0) !important;
    > a, > span {
      @include font-icon-after-inline($ifArrowRight, 17, 24);
      @include text-link;
      @include fontPx(21,24,0);
      color: $colorTextBlue;
      display: inline-block;
      text-transform: uppercase;
    }
    > span {
      color: black;
    }
  }

}

.mod_newsarchive {
  h1 {
    @include text-headline;
    @include fontPx(38,38,40);  // todo: define the correct margin
    display: inline-block;
    text-transform: uppercase;
    padding-bottom: rem-calc(8);
    border-bottom: 7px solid $colorBorderBlue;
    margin-left: $gridPadding;
  }
}



// COLOR THEMES

.mod_article {
  &.bg_blue,
  &.bg_grey {

    .news_teaser {
      h3 {
        a {
          color: white;
        }
      }

      .teaser {
        p {
          @include paragraph-small-white;
        }
      }

      p.more > a {
        @include link-standard-white;
      }
    }

  }
}












