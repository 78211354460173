@mixin fontPx($fontsize, $lineheight, $margin) {
  font-size:($fontsize) * 1px;
  line-height:($lineheight/$fontsize);
  margin-bottom:($margin) * 1px;
}


@mixin text-headline {
  font-family: 'Roboto Condensed', sans-serif; font-weight:700;
}

@mixin text-regular {
  font-family: 'Roboto Condensed', sans-serif; font-weight:400;
}

@mixin text-header {
  font-family: 'Roboto Condensed', sans-serif; font-weight:300;
}

@mixin text-footer {
  font-family: 'Roboto Condensed', sans-serif; font-weight:300;
}

@mixin text-link {
  font-family: 'Roboto Condensed', sans-serif; font-weight:700;
}

@mixin text-light {
  font-family: 'Roboto Condensed', sans-serif; font-weight:300;
}



@mixin text-standard {
  font-family: 'Arvo', sans-serif; font-weight:400;
}

@mixin text-bold {
  font-family: 'Arvo', sans-serif; font-weight:700;
}

@mixin text-italic {
  font-family: 'Arvo', sans-serif; font-weight:400; font-style: italic;
}

@mixin text-bolditalic {
  font-family: 'Arvo', sans-serif; font-weight:700; font-style: italic;
}


@mixin icon-font {
  //font-family: FontAwesome;
  font-family: icomoon;
}

