.event-info {
  @include text-light;
  @include fontPx(19,27,0);
  text-transform: uppercase;
  i {
    @include text-headline;
    font-style: normal;
  }
}

%event-teaser-base {
  @include clearfix_after;

  .event-date {
    float: left;
    width: 110px;
    .date {
      background-color: $colorBgEventDate;
      text-align:center;
      padding: 10px 0;
      border-bottom: 5px solid $colorBorderBlue;
      @include text-headline;
      @include fontPx(18,24,0);
    }
  }

  .event {
    margin-left: 140px;
    position: relative; top: -3px;
  }



  h3 {
    @include h3_teaser;
    color: $colorTextBlue;
    a {
      color: inherit;
    }
  }

  .teaser {
    margin-bottom: $element-paragraph-spacer-small;
    p {
      @include paragraph-small;
    }
    p strong {
      @include text-strong;
    }
  }

  p.more > a {
    @include link-standard;
  }

}



%event-teaser-long {
  @extend %event-teaser-base;
  padding-bottom: 40px;
  border-bottom: 1px solid black;
  margin-bottom: 30px;

  .event-icon {
    height: 78px;
    background-color: $colorBgBlue;
    background-image: url('images/bg_event_icon_global_large.png');
    background-position: center center;
    background-repeat: no-repeat;
  }

  &.platform {
    .event-icon {
      background-image: url('images/bg_event_icon_platform_large.png');
    }
  }

}

%event-teaser-short {
  @extend %event-teaser-base;

  margin-bottom: 10px;

  .teaser, p.more {
    display:none;
  }

  .event-icon {
    height: 46px;
    background-color: $colorBgEventDate;
    background-image: url('images/bg_event_icon_global.png');
    background-position: center center;
    background-repeat: no-repeat;
  }
  &.platform {
    .event-icon {
      background-image: url('images/bg_event_icon_platform.png');
    }
  }

  .event-date {
    .date {
      display:none;
    }
  }

}


// -------------------------------------------

.events-latest {

  .event-teaser:first-child {
    @extend %event-teaser-long;
  }

  .event-teaser:not(:first-child) {
    @extend %event-teaser-short;
  }

}


// -------------------------------------------

.mod_dzeventcalendaryear {
  .calendar-month {
    margin-bottom: rem-calc(80);
    ul {
      list-style-type: none; width: 100%;
      li {
        display: inline-block;
        width: 16.666%;
        > a {
          display: block;
          @include text-headline;
          @include fontPx(24, 24, 0);
          text-transform: uppercase;
          text-align: center;
          padding: rem-calc(15) 0;
          color: $colorText1EventsCalendar;
          background-color: $colorBgEventsCalendar;
          margin-left: 1px;
          margin-bottom: 1px;
          .year {
            color: $colorText2EventsCalendar;
          }
          &:hover, &.active {

          }
        }
        &.next-year {
          > a {
            color: $colorText1EventsCalendarNextyear;
            background-color: $colorBgEventsCalendarNextyear;
            .year {
              color: $colorText2EventsCalendarNextyear;
            }
          }
        }
        > a:hover, > a.active {
          color: $colorText1EventsCalendarActive;
          background-color: $colorBgEventsCalendarActive !important;
          .year {
            color: $colorText2EventsCalendarActive;
          }
        }
      }

    }
  }
}

@include breakpoint(medium down) {
  .mod_dzeventcalendaryear {
    .calendar-month {
      ul li > a .year {
        display: none;
      }
    }
  }
}

.mod_dzeventcalendaryear {
  .event-teaser {
    @extend %event-teaser-long;
  }
}


@include breakpoint(small down) {
  .events-latest .event-teaser {
    .event-date {
      display: none;
    }
    .event {
      margin-left:0 !important;
    }
  }
}



.mod_eventreader {
  h1 {
    @include h1_standard;
  }
  .event-info {
    @include text-regular;
    //@include fontPx(17,24,20);
    margin-bottom: 20px;
    text-transform: uppercase;


    .event-type {
      @include text-headline;
      font-style: italic;
    }
    time {
      @include text-headline;
    }
    .event.platform {
      .event-type:before { content: 'Platform | '; }
    }
    .event:not(.platform) {
      .event-type:before { content: 'Global | '; }
    }
  }


}