.no-js {
  @include breakpoint(small only) {
    .top-bar {
      display: none;
    }
  }

  @include breakpoint(medium) {
    .title-bar {
      display: none;
    }
  }
}

.no-js .top-bar {
  display: none;
}

// MAIN NAVIGATION

// reset

.js-drilldown-back {
  > a {
    &:before {
      border:0; margin:0;
      width: auto; height: auto;
      display: inline;
      vertical-align: baseline;
    }
  }
}

.menu .active > a {
  background-color: transparent;
}

.sticky-container {
  height: 60px;
  .sticky {

    z-index: 10000;
    width: 100%;
    height: 62px;
    background-color: transparent;
  }
}
.sticky-container {
  position: relative; //top: 60px;
  & + .mod_article {
    margin-top: -62px;
  }
}

.main-nav {
  position: absolute; right: 0; top: 0px; z-index: 10000;
  width:100%;
  .navigation-inside {
    //width:100%;
    //height: 60px;
  }

  .title-bar {
    padding: rem-calc(10) rem-calc(20) rem-calc(16) rem-calc(20);
    position: absolute; right: 0px; top: 0px;
    width: rem-calc(200);
    width:100%;
    text-align: right;
    background: transparent;

    .title-search {
      position: absolute; top: rem-calc(12); right: rem-calc(160); z-index: 1;
      > a {
        display: block; width: rem-calc(24); color: white;
        &:before {
          display: inline-block;
        }
        @include font-icon-before-inline($ifSearch, 24, 24);
      }
    }

    .title-menu {
      display: inline-block;
      position: absolute; top: rem-calc(13); right: rem-calc(75); z-index: 1;
      @include text-headline;
      @include fontPx(22,22,0);
    }

    .menu-icon {
      margin-top: rem-calc(2);
      margin-right:0;
    }

  }

  .top-bar {
    //display: none;
    margin-top: 0px;
    min-height: 1px;
    //background-color: black;
    background-color: transparent;
    padding: rem-calc(0) 0 rem-calc(10) 0;
    .top-bar-right {
      padding-top: rem-calc(60);
      background-color: black;
      min-height: inherit;
      width: auto !important;
    }

    .drilldown {
      min-width: rem-calc(420);
    }

    .is-drilldown {
      padding-bottom: rem-calc(20);

      li {
        border-bottom: 1px solid #222222;
        &.trail {
          > a {
            color: $colorTextBlue;
          }
        }
      }
      a {
        @include text-link;
        @include fontPx(24,28,0);
        display: block;
        color: white;
        &.active, &.trail {
          color: $colorTextBlue;
        }
      }

      .js-drilldown-back {
        a {
          @include font-icon-before-inline($ifArrowPrev, 24, 28);
          color: #85949b;
          //font-weight: 400;
        }
      }

      .level_1 > ul > li:first-child {
        a {
          @include font-icon-before-inline($ifArrowNext, 24, 28);
          color: #85949b;
        }
      }

      ul {
        background-color: black;
        li:last-child {
          //padding-bottom: 20px;
        }
      }

      .level_2 {
        li.is-submenu-item:not(.is-submenu-parent-item) {
          > a {
            padding-left: rem-calc(56);
          }
        }
      }

    }
  }

  .menu > li > a {
    padding: rem-calc(13) rem-calc(150) rem-calc(13) rem-calc(36);
    white-space: nowrap;
    text-transform: uppercase;
  }

  .menu-icon {
    width: 26px;
    &:after {
      height: 3px;
      //box-shadow: 0 8px 0 #ffffff, 0 16px 0 #ffffff;
    }
  }

}

.is-stuck .main-nav {
  .navigation-inside {
    //background-color: black;
  }
  .title-bar {
    background-color: black;
  }
}

.is-drilldown-submenu-parent > a::after {
  border: none;
  @include icon-font;
  font-weight:normal;
  content: $ifArrowNext;
  @include fontPx(24, 18, 0);
  font-weight: bold;
  color: $colorTextBlue;
  right: rem-calc(35);
}

.is-stuck {
  .main-nav .title-bar {
    //top:0px;
    padding-top: rem-calc(11);
    .title-search {
      top: rem-calc(14);
    }
    .title-menu {
      top: rem-calc(15);
    }
  }
}


// BREADCRUMB

.breadcrumb-nav {

  @include page-padding;
  > .breadcrumb-inside {
    @include page-width;
    padding-top: rem-calc(25);
    padding-bottom: rem-calc(8);
  }

  .breadcrumb-listcontainer {
    @include single-column();
  }
  ul {
    border-bottom: 1px solid black;
  }

  li {
    display: inline-block;
    @include text-regular;
    @include fontPx(18,24,0);
    color: $colorLinkHighlight;
    a {

      color: $colorTextDefault;
      &:hover {
        color: $colorLinkHighlight;
      }

    }
    &:not(:last-child) a:after {
      content: '»';
      padding-left: 3px;
    }
  }

}



.dropdown-nav {
  margin-bottom: rem-calc(40);
  .dropdown-nav-select-box {
    position: relative; z-index:1;
    background-color: $colorBgFormField;
    &:after {
      position:absolute; top:0; right:0; z-index:1;
      width: 53px; height: 53px; text-align: center;
      background-color: $grey;
      color: white;
      @include icon-font;
      @include fontPx(20,53,0);
      content: $ifArrowDown;
    }
  }
  .dropdown-nav-select {
    position: relative; z-index:2;
    @include form-select;
    @include form-select-custom;
    cursor: pointer;
    option {
      background-color: $colorBgFormField;
    }
  }
}