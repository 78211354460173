body, a {
  color: $colorTextDefault;
}

.invisible, .hideme {
  left: -32768px;
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.mod_navigation a[href].invisible {
  left: -32768px;
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: auto;
  width: 100%;
  z-index: 1000;
  &:focus {
    position: static;
    left: auto;
    width: auto;
    background: white;
  }
}

h6 {
  @extend .invisible;
}