

.col-75x25.teaser {
  margin-bottom: rem-calc(5);
  &:last-of-type {
    margin-bottom: rem-calc($element-bottom-spacer);
  }
  .columns {
    //padding:0;
  }
  .text-column {
    background-color: white;
    padding: rem-calc(54);
    > div:last-child {
      margin-bottom: 0;
    }
  }

  .image-column {
    padding:0;
    .ce_image {
      width: 100%;
      height: inherit;
      margin-left: 3px;
      text-align: center;
      position: relative;
      background-color: white;
      .image_container {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

}

.bg_standard {
  .col-75x25.teaser {
    .text-column {
      background-color: $midgrey;
    }
    .image-column {
      .ce_image {
        border: 3px solid $midgrey;
      }
    }
  }
}

@include breakpoint(medium down) {
  .col-75x25.teaser {
    .text-column {
      margin-bottom: rem-calc(2);
    }
    .image-column {
      height: auto;
      .ce_image {
        margin:0;
        .image_container {
          position: static;
          transform: none;
          padding: rem-calc(20) 0;
        }
      }
    }
  }
}