
.ce_accordion {

  .toggler, .toggler_closed {
    @include headerbar;
    background: $colorBgAccordionHeader;
    margin-bottom:4px;

    &.ui-state-active {
      @include headerbar-down;
      background: $colorBgAccordionHeaderActive;
    }
    &:not(.ui-state-active) {
      @include headerbar-right;
    }
    &:hover {
      background: $colorBgAccordionHeaderActive;
    }
  }

  .accordion {
    background-color: $colorBgAccordionContent;
    padding: rem-calc(40) rem-calc(45) rem-calc(10) rem-calc(45);
    margin-bottom:4px;
  }

  &:first-child {
    //padding-top:30px;

  }

  &:last-child {
    .accordion {
    }
  }
}

.ce_accordion-single {

  margin-bottom: $element-bottom-spacer;

  .toggler {
    @include headerbar;
    background-image: url('images/bg_accicon_agenda.png'), url('images/bg_acc_single_toggler.png');
    background-repeat: no-repeat, repeat-x;
    background-position: left center, left center;
    padding: 0 rem-calc(60) 0 rem-calc(112);
    height: 73px;
    overflow: hidden;
    > div {
      position: relative; top: 50%; transform: translateY(-50%);
    }

    &.ui-state-active {
      @include headerbar-down;
    }
    &:not(.ui-state-active) {
      @include headerbar-right;
    }
  }

  &.agenda .toggler { background-image: url('images/bg_accicon_agenda.png'), url('images/bg_acc_single_toggler.png'); }
  &.caadp .toggler { background-image: url('images/bg_accicon_caadp.png'), url('images/bg_acc_single_toggler.png'); }
  &.climate .toggler { background-image: url('images/bg_accicon_climate.png'), url('images/bg_acc_single_toggler.png'); }
  &.gender .toggler { background-image: url('images/bg_accicon_gender.png'), url('images/bg_acc_single_toggler.png'); }
  &.governance .toggler { background-image: url('images/bg_accicon_governance.png'), url('images/bg_acc_single_toggler.png'); }
  &.nutrition .toggler { background-image: url('images/bg_accicon_nutrition.png'), url('images/bg_acc_single_toggler.png'); }
  &.privatesector .toggler { background-image: url('images/bg_accicon_privatesector.png'), url('images/bg_acc_single_toggler.png'); }


  .accordion {
    padding: rem-calc(60) 0 0 0;
    border-bottom: 1px solid $darkgrey;
  }


}