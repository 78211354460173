// Spacings

$gridSpacer: 60px;
$gridPadding: ($gridSpacer/2);



$page-padding: (
        small: 10px,
        inter: 20px,
        medium: 30px,
);


$page-width: (
        inter: 550,
        medium: 1200,
);



$paddingArticleTop: 65px;
$paddingArticleBottom: 65px;


$grid-spacer-vertical: (
        small: 30px,
        medium: 0px,
);

$grid-spacer-vertical-33x33x33: (
        small: 30px,
        large: 0px,
);

$element-bottom-spacer: 50px;
//$element-paragraph-spacer: 27px;
//$element-paragraph-spacer-small: 24px;
$element-paragraph-spacer: 20px;
$element-paragraph-spacer-small: 17px;

$multiColumnGap2: 60px;
$multiColumnGap3: 30px;


// Colors

$darkgrey: #303030;
$grey: #758389;
//$lightgrey: #f8f8f8;
$lightgrey: #efeff1;
$midgrey: #efeff1;
$blue: #008aa7;

$colorHeadlineH1: $blue;

$colorTextDefault: $darkgrey;
$colorTextBlue: $blue;
$colorTextFormButton: white;
$colorTextAnkerlink: $grey;

$colorBgBlue: $blue;
$colorBgEventDate: $midgrey;
$colorBgFooter: #3b3a38;

$colorBorderBlue: $blue;

$colorLinkHighlight: $blue;

// Forms
$colorBgFormField: $midgrey;
$colorBgFormButton: $blue;

// Event Calendar
$colorBgEventsCalendar: $grey;
$colorText1EventsCalendar: #ffffff;
$colorText2EventsCalendar: #b2bcc0;

$colorBgEventsCalendarActive: #008aa7;
$colorText1EventsCalendarActive: #ffffff;
$colorText2EventsCalendarActive: #51b9cf;

$colorBgEventsCalendarNextyear: $midgrey;
$colorText1EventsCalendarNextyear: $grey;
$colorText2EventsCalendarNextyear: #b2bcc0;

// Tabs
$colorBgTab: $grey;
$colorTextTab: white;
$colorBgTabActive: $blue;
$colorTextTabActive: white;
$colorBgTabHover: $darkgrey;

// Tables
$colorBgTableHeadline: $colorBgBlue;
$colorBgTableHeader: $grey;
$colorBgTableContentEven: $midgrey;
$colorBgTableContentOdd: transparent;

// Accordion
$colorBgAccordionHeader: $grey;
$colorBgAccordionHeaderActive: $blue;
$colorBgAccordionContent: $lightgrey;


// Downloads
$downlinkIndent: 38;

// Article backgrounds
.main > .mod_article {
  &.bg_blue {
    background-color: $blue;
  }
  &.bg_grey {
    background-color: $grey;
  }
  &.bg_lightgrey {
    background-color: $lightgrey;
  }
}


// Icon Font

$ifArrowRight: "\e90c";
$ifArrowLeft: '\e90b';

$ifArrowDown: '\e909';

$ifArrowFirst: '\e90a';
$ifArrowPrev: '\e90b';
$ifArrowNext: "\e90c";
$ifArrowLast: '\e90d';

$ifDownload: '\e905';
$ifMail: '\e901';
$ifLinkExtern: '\e906';
$ifLinkStandard: '\e907';

$ifSearch: '\e902';
$ifDot: '\e903';
$ifAnchor: '\e900';
$ifTop: '\e904';

$ifTwitter: '\e908';


/* FontAwesome

$ifArrowRight: '\f054';
$ifArrowLeft: '\f053';

$ifArrowDown: '\f107';

$ifArrowFirst: '\f100';
$ifArrowPrev: '\f104';
$ifArrowNext: '\f105';
$ifArrowLast: '\f101';

$ifDownload: '\f019';
$ifMail: '\f1fa';
$ifLinkExtern: '\f08e';
$ifLinkStandard: '\f0c1';

$ifSearch: '\f002';
$ifDot: '\f111';
$ifAnchor: '\f13d';
$ifTop: '\f077';
*/