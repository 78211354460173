// Resets

.mod_article:not(.topic-tabs) {

  .tabs { border: 0; background: transparent; }
  .tabs-content { border: 0; background: transparent; }
  .tabs-panel { padding: 0; }

  .ce_dz_tabcontainer {

    .tabs {
      margin-bottom: rem-calc(50);
    }

    .tabs-title:not(:last-child) {
      padding-right: rem-calc(18);
    }

    .tabs-title > a {
      @include text-headline;
      @include fontPx(21, 53, 0);
      background-color: $colorBgTab;
      color: $colorTextTab;
      text-transform: uppercase;
      display: block;
      padding: 0 rem-calc(25);
    }

    .tabs-title.is-active > a {
      background: $colorBgTabActive; color: $colorTextTabActive;
    }

    .tabs-title:not(.is-active) > a:hover {
      background: $colorBgTabHover;
    }

    .tabs-content {}

  }

  @include breakpoint(small down) {
    .ce_dz_tabcontainer {
      .tabs-title {
        width: 100%;
        padding-right: 0 !important;
        margin-bottom: 2px;
      }
    }
  }

}
