
// abstract classes, only to be extended
.inside {
  @include page-width;
}

.mod_article {
  position: relative; z-index: 100;
}

.main > .mod_article {
  @include page-padding;

  &.bg_hasimage {
    background-repeat: no-repeat;
    background-position: center center;
    //background-size: cover;

  }

  &.article_padding_top {
    padding-top: $paddingArticleTop;
  }
  &.article_padding_bottom {
    padding-bottom: $paddingArticleBottom;
  }

}


.main > .mod_article > .article_inside {
  @include page-width;
}



@media screen and (min-width:960px) and (max-width: 1300px) {  // todo: use variable

  .main > .mod_article.slider > .article_inside {
    .slick-slide {
      padding-left: 60px !important; // todo: use variable
      padding-right: 60px !important; // todo: use variable
    }
  }

}