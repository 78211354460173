.footer {
  background-color: $lightgrey;
}

.nav_footer {

  background-color: $colorBgFooter;
  @include page-padding;

  ul {
    text-align:center;
    padding: 12px 0;
  }
  li {
    display: inline-block;
    @include text-footer;
    @include fontPx(16,16,0);
    color: white;

    &:not(:last-child):after {
      content:'|';
      padding: 0 5px 0 10px;
    }

    > a, > span {
      color: inherit;
    }
  }

}


.social-links {

  @include page-padding;

  ul {
    text-align:center;
    padding: 0px 0 45px 0;
  }
  li {
    display: inline-block;
    &:not(:last-child) {
      margin-right: 30px;
    }
  }

}

.copyright-header-container {
  @include page-padding;
  padding-top: rem-calc(12);
  padding-bottom: rem-calc(12);
  background-color: $grey;
  .copyright-header-inside {
    @include page-width;
    text-align:center;
    p {
      display: inline-block;;
      background:url('images/bg_photo_copyright.png') no-repeat left center;
      padding-left: 36px;
      @include text-regular;
      @include fontPx(16,22,0);
      color: white;
      span {
        display: inline-block;
        white-space: nowrap;
      }
    }
  }
}
